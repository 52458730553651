(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageEventSources', [
			'$rootScope',
			'utilities',
			'dataStore',
			'seedcodeCalendar',
			'manageCalendarActions',
			'filemakerScript',
			'filemakerServer',
			'googleCalendar',
			'googleSheets',
			'salesforce',
			'salesforceAPI',
			'officeCalendar',
			'dayback',
			'basecamp',
			'filemakerJS',
			'trello',
			'booking',

			manageEventSources,
		]);

	function manageEventSources(
		$rootScope,
		utilities,
		dataStore,
		seedcodeCalendar,
		manageCalendarActions,
		filemakerScript,
		filemakerServer,
		googleCalendar,
		googleSheets,
		salesforce,
		salesforceAPI,
		officeCalendar,
		dayback,
		basecamp,
		filemakerJS,
		trello,
		booking
	) {
		var sourceDefinitions = arguments;
		var sourceTemplates = [];
		const getEventQueue = {};

		//Global values for filemaker scripting. Allows us to group our requests for the source type
		var options = {
			count: 0,
			queue: 0,
		};

		//Event sources we pass to fullcalendar to render
		var eventSources = seedcodeCalendar.init('eventSources', [], true);

		//Sharing source
		var sharingSourceTemplate;

		return {
			init: init,
			initializeTemplates: initializeTemplates,
			getTemplates: getTemplates,
			getTemplate: getTemplate,
			getSources: getSources,
			create: create,
			getOptions: getOptions,
			getSchedules: getSchedules,
			loadSchedules: loadSchedules,
		};
		//Get the object
		function init() {
			eventSources = seedcodeCalendar.init('eventSources', [], true);
		}

		function authAndLoadSchedules(
			sourceTemplate,
			source,
			authFunction,
			statusOnly,
			forceConsent,
			preventScheduleLoad,
			secondaryAuth,
			callback
		) {
			authFunction(
				function (result) {
					if (result && !preventScheduleLoad) {
						sourceTemplate.schedules(loadSchedules);
					}
					if (callback) {
						callback(result);
					}
				},
				source,
				statusOnly,
				forceConsent,
				secondaryAuth,
				sourceTemplate
			);
		}

		function getSources() {
			var result = [];
			for (var i = 0; i < eventSources.length; i++) {
				if (eventSources[i].enabled) {
					result.push(eventSources[i]);
				}
			}
			return result;
		}

		function initializeTemplates() {
			var sourceTemplate;
			// Load source templates only if it hasn't already been loaded
			if (sourceTemplates && sourceTemplates.length) {
				return;
			}
			for (var i = 0; i < sourceDefinitions.length; i++) {
				if (sourceDefinitions[i].getConfig) {
					sourceTemplate = sourceDefinitions[i].getConfig();
					addSourceMethods(sourceDefinitions[i], sourceTemplate);
					addSourceFieldMap(sourceDefinitions[i], sourceTemplate);
					sourceTemplates.push(sourceTemplate);
				}
			}

			//Sharing source template
			sharingSourceTemplate = getTemplate(6);
		}

		function addSourceMethods(sourceDependency, template) {
			template.disableSchedule = sourceDependency.disableSchedule
				? sourceDependency.disableSchedule
				: null;
			template.getEvents = sourceDependency.getEvents
				? sourceDependency.getEvents
				: null;
			template.getOneEvent = sourceDependency.getOneEvent
				? sourceDependency.getOneEvent
				: null;
			template.getMapEvents = sourceDependency.getMapEvents
				? sourceDependency.getMapEvents
				: null;
			template.editEvent = sourceDependency.editEvent
				? sourceDependency.editEvent
				: null;
			template.deleteEvent = sourceDependency.deleteEvent
				? sourceDependency.deleteEvent
				: null;
			template.mutateEvents = sourceDependency.mutateEvents
				? sourceDependency.mutateEvents
				: null;
			template.getContacts = sourceDependency.getContacts
				? sourceDependency.getContacts
				: null;
			template.getProjects = sourceDependency.getProjects
				? sourceDependency.getProjects
				: null;
			template.getResources = sourceDependency.getResources
				? sourceDependency.getResources
				: null;
			template.getUnscheduled = sourceDependency.getUnscheduled
				? sourceDependency.getUnscheduled
				: null;

			template.getObjectInfo = sourceDependency.getObjectInfo;

			template.getResourceObjects = sourceDependency.getResourceObjects;

			template.showDetail = sourceDependency.showDetail
				? sourceDependency.showDetail
				: null;
			template.getEventEditQueue = sourceDependency.getEventEditQueue
				? sourceDependency.getEventEditQueue
				: null;
			template.changeScheduleName = sourceDependency.changeScheduleName
				? sourceDependency.changeScheduleName
				: null;
			template.changeScheduleColor = sourceDependency.changeScheduleColor
				? sourceDependency.changeScheduleColor
				: null;

			template.assignColor = sourceDependency.assignColor
				? sourceDependency.assignColor
				: null;

			template.switchAccount = sourceDependency.switchAccount
				? sourceDependency.switchAccount
				: null;
			template.deauthFunction = sourceDependency.deauthorize
				? sourceDependency.deauthorize
				: null;

			template.updateRepeatingEventUntil =
				sourceDependency.updateRepeatingEventUntil
					? sourceDependency.updateRepeatingEventUntil
					: null;
			template.deleteRepeatingInstance =
				sourceDependency.deleteRepeatingInstance
					? sourceDependency.deleteRepeatingInstance
					: null;
			template.deleteSourceEvent = sourceDependency.deleteSourceEvent
				? sourceDependency.deleteSourceEvent
				: null;
			template.updateSourceEvent = sourceDependency.updateSourceEvent
				? sourceDependency.updateSourceEvent
				: null;
			template.getRepeatingRule = sourceDependency.getRepeatingRule
				? sourceDependency.getRepeatingRule
				: null;
			template.repeatingConfig = sourceDependency.repeatingConfig
				? sourceDependency.repeatingConfig
				: null;
			template.clearRecurringFields =
				sourceDependency.clearRecurringFields
					? sourceDependency.clearRecurringFields
					: null;
			template.calculateEndDateTime =
				sourceDependency.calculateEndDateTime
					? sourceDependency.calculateEndDateTime
					: null;

			template.getPicklist = sourceDependency.getPicklist
				? sourceDependency.getPicklist
				: null;
			(template.getFieldData = sourceDependency.getFieldData
				? sourceDependency.getFieldData
				: null),
				(template.refresh = sourceDependency.refresh
					? sourceDependency.refresh
					: null);
			template.eventFocus = sourceDependency.eventFocus
				? sourceDependency.eventFocus
				: null;

			template.todoConfig = sourceDependency.todoConfig
				? sourceDependency.todoConfig
				: null; // Only used in Basecamp?
			template.onboarding = sourceDependency.onboarding
				? sourceDependency.onboarding
				: null;

			template.sourceButtons = sourceDependency.sourceButtons
				? sourceDependency.sourceButtons()
				: null;

			template.showEventOnLayout = sourceDependency.showEventOnLayout
				? sourceDependency.showEventOnLayout
				: null;

			template.onSignOut = sourceDependency.onSignOut
				? sourceDependency.onSignOut
				: null;

			template.schedules = function (callback) {
				var sourceSchedule = loadSourceSchedule(
					sourceDependency.getSchedules,
					callback,
					template
				);
				return sourceSchedule;
			};

			template.getSecondaryAuth = sourceDependency.getSecondaryAuth;

			template.deleteSecondaryAuth = sourceDependency.deleteSecondaryAuth;

			template.authFunction = sourceDependency.auth
				? function (
						callback,
						source,
						statusOnly,
						forceConsent,
						preventScheduleLoad,
						secondaryAuth
					) {
						authAndLoadSchedules(
							template,
							source,
							sourceDependency.auth,
							statusOnly,
							forceConsent,
							preventScheduleLoad,
							secondaryAuth,
							callback
						);
					}
				: null;
		}

		function addSourceFieldMap(sourceDependency, template) {
			template.fieldMap = sourceDependency.getFieldMap();
			template.unusedMap = sourceDependency.getUnusedMap();
			template.allowHTMLMap = sourceDependency.getAllowHTMLMap();
			template.hiddenFieldMap = sourceDependency.getHiddenFieldMap();
			template.readyOnlyFieldMap = sourceDependency.getReadOnlyFieldMap();
			template.allowTextFieldMap =
				sourceDependency.getAllowTextFieldMap();
		}
		function getTemplates() {
			var config = seedcodeCalendar.get('config');
			var share = seedcodeCalendar.get('share');
			var sourceTypes = config.sourceTypes;
			var shareSourceTypeID = share ? share.sourceTypeID : null;

			var template;
			var output = [];
			var activeSourceTypes = {};

			for (var i = 0; i < sourceTemplates.length; i++) {
				if (sourceTemplates[i].available) {
					// output.push(getTemplate(sourceTypes[sourceType].id));

					template = initTemplate(sourceTemplates[i]);
					if (template.status && template.status.hidden) {
						// Don't add template if the source is marked as hidden
						continue;
					}

					output.push(template);
					if (sourceTemplates[i].status.enabled) {
						activeSourceTypes[sourceTemplates[i].propertyName] = {
							id: sourceTemplates[i].id,
						};
					}

					if (shareSourceTypeID === sourceTemplates[i].id) {
						shareSourceTypeID = null;
					}
				}
			}

			//Check if the share is from a source type the user does not currently have. If it doesn't exist add it.
			if (shareSourceTypeID) {
				output.push(getTemplate(shareSourceTypeID));
			}

			config.activeSourceTypes = activeSourceTypes;

			// Return sorted output
			return output.sort(compare);

			// sort function
			function compare(a, b) {
				// Use toUpperCase() to ignore character casing
				var nameA = a.name.toUpperCase();
				var nameB = b.name.toUpperCase();

				var comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				} else if (nameA < nameB) {
					comparison = -1;
				}
				return comparison;
			}
		}

		function getTemplate(id) {
			for (var i = 0; i < sourceTemplates.length; i++) {
				if (sourceTemplates[i].id === id) {
					return initTemplate(sourceTemplates[i]);
				}
			}
		}

		function initTemplate(template) {
			var config = seedcodeCalendar.get('config');
			var sourceTypes = config ? config.sourceTypes : {};

			//Assign status object if one doesn't exist yet - We use this for storing auth status and loading status
			if (!template.status) {
				template.status = {};
			}

			//Don't disable the source type if this is a share
			if (!config?.isShare) {
				template.status.enabled =
					!sourceTypes[template.propertyName] ||
					sourceTypes[template.propertyName].disabled
						? false
						: true;

				template.status.hidden =
					(!sourceTypes[template.propertyName] && template.removed) ||
					(sourceTypes[template.propertyName] &&
						sourceTypes[template.propertyName].hidden)
						? true
						: false;
			} else {
				template.status.enabled = true;
			}

			return template;
		}

		function getOptions() {
			return options;
		}

		//Add to the event source list using a template
		function create(schedule, sourceTemplate) {
			var eventSource = {
				name: sourceTemplate.name,
				enabled: schedule.status.selected,
				editable:
					!schedule.readOnly &&
					!schedule.isUnavailable &&
					!schedule.isMeasureOnly, //sourceTemplate.editable,
				colorType: sourceTemplate.colorType,
				customActionsEditable: sourceTemplate.customActionsEditable,
				disableSchedule: sourceTemplate.disableSchedule,
				editEvent: function (
					event,
					revertObject,
					revertFunc,
					changes,
					editID,
					callback
				) {
					sourceTemplate.editEvent(
						event,
						revertObject,
						revertFunc,
						changes,
						editID,
						callback,
						schedule
					);
				},
				deleteEvent: function (event, callback) {
					sourceTemplate.deleteEvent(event, callback, schedule);
				},

				showDetail: sourceTemplate.showDetail,
				showEventOnLayout: sourceTemplate.showEventOnLayout,
				getContacts: sourceTemplate.getContacts,
				getProjects: sourceTemplate.getProjects,
				getResources: sourceTemplate.getResources,
				getFieldData: sourceTemplate.getFieldData,
				getPicklist: sourceTemplate.getPicklist,
				getOneEvent: sourceTemplate.getOneEvent,

				refresh: sourceTemplate.refresh,

				eventFocus: sourceTemplate.eventFocus,

				mutateEvents: sourceTemplate.mutateEvents,

				getSharedEvents: sharingSourceTemplate.getEvents,
				editSharedEvent: sharingSourceTemplate.editEvent,
				deleteSharedEvent: sharingSourceTemplate.deleteEvent,
				unscheduled: function (callback, fetchID) {
					//We only want to run our function if the schedule is selected
					if (
						schedule.status.selected &&
						sourceTemplate.getUnscheduled
					) {
						sourceTemplate.getUnscheduled(
							callback,
							schedule,
							fetchID
						);
					} else {
						callback();
					}
				},

				events: function (
					start,
					end,
					timezone,
					callback,
					fetchID,
					eventID
				) {
					const queryID = utilities.generateUID();
					//We only want to run our function if the schedule is selected
					if (
						schedule.status.selected &&
						(!schedule.queryOnGeocode || !schedule.isMapOnly)
					) {
						getEventQueue[schedule.id] = queryID;
						sourceTemplate.getEvents(
							start,
							end,
							timezone,
							onCallback,
							schedule,
							options,
							eventID,
							fetchID,
							null
						);
					} else {
						callback();
					}

					function onCallback(result) {
						// If there is not element then we aren't displaying the calendar
						if (!seedcodeCalendar.get('element')) {
							return;
						}

						if (
							queryID !== getEventQueue[schedule.id] ||
							!schedule.status.selected
						) {
							callback();
						} else {
							callback(result);
						}
					}
				},
				mapEvents: function (bounds, callback, fetchID, eventID) {
					const queryID = utilities.generateUID();
					//We only want to run our function if the schedule is selected
					if (
						sourceTemplate.getMapEvents &&
						schedule.status.selected &&
						schedule.isMapOnly &&
						schedule.queryOnGeocode
					) {
						getEventQueue[schedule.id] = queryID;
						sourceTemplate.getMapEvents(
							bounds,
							onCallback,
							schedule,
							options,
							eventID,
							fetchID
						);
					} else {
						callback();
					}

					function onCallback(result) {
						// If there is not element then we aren't displaying the calendar
						if (!seedcodeCalendar.get('element')) {
							return;
						}

						if (
							queryID !== getEventQueue[schedule.id] ||
							!schedule.status.selected
						) {
							callback();
						} else {
							callback(result);
						}
					}
				},
				changeScheduleName: function (name, callback) {
					sourceTemplate.changeScheduleName(name, callback, schedule);
				},
				changeScheduleColor: function (color, callback) {
					sourceTemplate.changeScheduleColor(
						color,
						callback,
						schedule
					);
				},
				getEventEditQueue: sourceTemplate.getEventEditQueue
					? sourceTemplate.getEventEditQueue
					: undefined,
				assignColor: sourceTemplate.assignColor
					? sourceTemplate.assignColor
					: undefined,
				updateRepeatingEventUntil:
					sourceTemplate.updateRepeatingEventUntil
						? sourceTemplate.updateRepeatingEventUntil
						: undefined,
				deleteRepeatingInstance: sourceTemplate.deleteRepeatingInstance
					? sourceTemplate.deleteRepeatingInstance
					: undefined,
				deleteSourceEvent: sourceTemplate.deleteSourceEvent
					? sourceTemplate.deleteSourceEvent
					: undefined,
				updateSourceEvent: sourceTemplate.updateSourceEvent
					? sourceTemplate.updateSourceEvent
					: undefined,
				getRepeatingRule: sourceTemplate.getRepeatingRule
					? sourceTemplate.getRepeatingRule
					: undefined,
				repeatingConfig: sourceTemplate.repeatingConfig
					? sourceTemplate.repeatingConfig
					: undefined,
				todoConfig: sourceTemplate.todoConfig
					? sourceTemplate.todoConfig
					: undefined,
				calculateEndDateTime: sourceTemplate.calculateEndDateTime
					? sourceTemplate.calculateEndDateTime
					: undefined,
				clearRecurringFields: sourceTemplate.clearRecurringFields
					? sourceTemplate.clearRecurringFields
					: undefined,
			};
			//Pass our new source back to the schedule
			schedule.sourceTypeID = sourceTemplate.id;
			// Pass the sourceTemplate to schedule
			schedule.sourceTemplate = sourceTemplate;
			//If the event source is read only then the schedule should inherit that
			if (!eventSource.editable) {
				schedule.editable = false;
			} else if (schedule.editable === undefined) {
				schedule.editable = true;
			}
			schedule.source = eventSource;

			eventSources.push(eventSource);

			return eventSource;
		}

		function loadSourceSchedule(loadScheduleFunc, callback, template) {
			//We could run a routine here where we are loading a source
			template.status.loading = true;
			//Return with the result of running our load schedule function
			return loadScheduleFunc(runCallback, template);

			//Callback function so we can broadcast when our process is done
			function runCallback(result) {
				template.status.loading = false;

				//Could run a routine that shows schedule loading is done
				callback(result, template);
			}
		}

		async function getSchedules(fetchCallback) {
			const sources = seedcodeCalendar.get('sources');
			const sourceTemplates = seedcodeCalendar.get('sourceTemplates');
			const promiseArray = [];
			let lastSourceTypeID;

			for (let sourceTemplate of sourceTemplates) {
				//Clear potential event cache
				if (sourceTemplate.hasEventCache) {
					sourceTemplate.refresh(null, null);
				}
				for (let source of sources) {
					if (
						sourceTemplate?.status?.enabled &&
						sourceTemplate?.id === source.sourceTypeID
					) {
						lastSourceTypeID = sourceTemplate.id;

						// Add schedule function to promise array
						promiseArray.push(
							new Promise((resolve, reject) => {
								sourceTemplate.schedules((schedules) => {
									// If there is not element then we aren't displaying the calendar
									if (!seedcodeCalendar.get('element')) {
										reject('Calendar no longer shown');
										return;
									}
									if (fetchCallback) {
										fetchCallback(
											schedules,
											sourceTemplate
										);
									}
									resolve(schedules);
								});
							})
						);
						break;
					}
				}
			}

			try {
				const allSchedules = await Promise.all(promiseArray);
				// Return a joined array of all schedules
				return allSchedules.reduce((existingArray, currentValue) => {
					return currentValue
						? existingArray.concat(currentValue)
						: existingArray;
				}, []);
			} catch (err) {
				throw new Error(err);
			}
		}

		//Initialize our schedules
		function loadSchedules(
			result,
			sourceTemplate,
			enableOverrideFunc,
			callback,
			loadCompleteCallback
		) {
			var enabled;
			var schedules = seedcodeCalendar.get('schedules') || [];
			var savedSchedules = dataStore.getState('selectedSchedules');
			var settings = seedcodeCalendar.get('settings');
			var userSettings = seedcodeCalendar.get('userSettings');
			var resources = seedcodeCalendar.get('resources');
			var calendarActions = seedcodeCalendar.get('calendarActions');
			var actionCallbacks;
			var preventDefaultResult;
			var scheduleKey;
			var hasSelected;
			var defaultResource;

			//Set default selection count to 1/3 of possible calendars for this source
			var defaultSelectionCount = Math.max(
				1,
				Math.floor(result.length / 3)
			);

			//Try just in case the stored data is malformed
			try {
				savedSchedules = savedSchedules
					? JSON.parse(savedSchedules)
					: null;
			} catch (error) {
				savedSchedules = null;
			}
			for (var i = 0; i < result.length; i++) {
				scheduleKey = utilities.stringToID(result[i].id);
				enabled = true; //Default the schedule to show unless otherwise specified
				//Set schedule identifier
				result[i].id = !result[i].id
					? sourceTemplate.id + '-' + i
					: result[i].id;

				result[i].originalDefaultResource =
					settings?.defaultResources?.[scheduleKey];

				defaultResource =
					userSettings?.defaultResources?.[scheduleKey] ||
					settings?.defaultResources?.[scheduleKey];
				if (userSettings?.defaultResources?.[scheduleKey]?.enabled) {
					result[i].enableUserDefaultResource = true;
				}

				if (defaultResource) {
					for (
						var resourceCount = 0;
						resourceCount < resources.length;
						resourceCount++
					) {
						if (resources[resourceCount].id === defaultResource) {
							//Create object with specific properties so we don't get unintended angular byproducts like $$hashKey
							result[i].defaultResource = {
								id: resources[resourceCount].id,
								name: resources[resourceCount].name,
							};

							if (
								userSettings?.defaultResources?.[scheduleKey]
									?.enabled
							) {
								result[i].enableUserDefaultResource = true;
								result[i].userDefaultResource = {
									...result[i].defaultResource,
								};
							}
						}
					}
				}

				//Restore schedule selections if they have been saved
				if (savedSchedules) {
					for (var ii = 0; ii < savedSchedules.length; ii++) {
						if (
							savedSchedules[ii] ===
							result[i].id + result[i].name
						) {
							enabled = false;
						}
					}
				} else {
					//If we have explicitely set the schedule to disabled or if we are dealing with default state only load 1/3 of calendars for this source
					if (
						result[i].enabled === false ||
						(result[i].enabled !== true &&
							i >= defaultSelectionCount)
					) {
						enabled = false;
					}
				}
				//Run an optional function that can override the previously set selection state. Usefull for forcing a schedule on or off under certain circumstances
				if (enableOverrideFunc) {
					enabled = enableOverrideFunc(result[i], enabled);
				}

				result[i].status.selected = enabled;

				if (enabled) {
					hasSelected = true;
				}

				create(result[i], sourceTemplate);

				schedules.push(result[i]);
			}

			//Run on load calendar actions
			if (calendarActions && calendarActions.schedulesFetched) {
				actionCallbacks = {
					confirm: function () {
						applySchedules(sourceTemplate);
					},
					cancel: function () {
						loadComplete();
					},
				};

				//Run load actions
				preventDefaultResult = manageCalendarActions.runCalendarActions(
					calendarActions.schedulesFetched,
					actionCallbacks
				);
				if (preventDefaultResult) {
					return;
				}
			}

			applySchedules(sourceTemplate);

			function loadComplete(hasSelected) {
				//Run a loading complete Callback if one exists;
				if (loadCompleteCallback) {
					loadCompleteCallback(schedules, hasSelected);
				}
				$rootScope.$broadcast('schedules', schedules);
				$rootScope.$broadcast('eventSources', getSources());
				return schedules;
			}

			function applySchedules(template) {
				const calendarInitialized = seedcodeCalendar
					.get('element')
					?.fullCalendar('initialized');
				let hasSelected = false;

				for (const schedule of schedules) {
					if (schedule.sourceTypeID !== template.id) {
						// Ignore if this isn't the current source type we are looking at
						continue;
					}
					if (schedule.status.selected) {
						hasSelected = true;
						if (!schedule.source.enabled) {
							schedule.source.enabled = true;
						}
					}
					if (calendarInitialized && schedule.source.enabled) {
						seedcodeCalendar
							.get('element')
							.fullCalendar('addEventSource', schedule.source);
						if (callback) {
							callback(template);
						}
					}
				}

				loadComplete(hasSelected);
			}
		}
	}
})();
